/*
** React CORS friendly Single Page Application - https://github.com/aws-samples/react-cors-spa 
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated
documentation files (the "Software"), to deal in the Software without restriction, including without limitation
the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software,
and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of
the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR
COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT,
TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/

import invitationTransparent from './invitation_transparent.png';
import './App.css';
import Sparkle from 'react-sparkle'

// To be replaced by the endpoint of the API deployed through the CloudFormation Template
// const APIEndPoint = 'https://376f7red78.execute-api.us-east-1.amazonaws.com/v1/hello'

function App() {
  return (
    <div className="App">
	<Sparkle color="green" count={50} fadeOutSpeed={20} overflowPx={80} minSize={10} maxSize={20} flicker={false}/>
        <header className="App-header">
          <img src={invitationTransparent} className="App-MainLogo" alt="rim" />
        </header>
	<p className="Rsvp">Kindly RSVP <a href="https://forms.gle/bJCWR9UCTfPVdwWb9" target='_blank' rel='noreferrer'>Here</a></p>
	<p>Want to shower Rim and Kofi with a gift? 
	<br/><i><a href="https://www.amazon.de/baby-reg/rimna-adam-rimjr-thequeen-april-2024-berlin/38GWEQISABKWM" target='_blank' rel='noreferrer'>Click here for the registry where you can choose from things they need</a></i><br/>
	<small>Diapers, swaddling cloths, and baby wipes are also a wonderful way to show your love!</small></p>

	<p>If you have any questions or want to RSVP via text, please reach out to Bex: +49 174 6609816</p>
	<p>There will be snacks and light refreshments but feel free to bring your own drinks
	<br/><i>Alcohol is welcome and encouraged</i></p>
	<p>We hope to see you there ❤️</p>
    </div>
  );
}

export default App;
